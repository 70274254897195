@import "variables";

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.scss";
@import "./layout/sidebar";
@import "./layout/container";
@import "./custom";
@import "./layout/amplifyoveride";
@import "./layout/dashboard";


/*******************/
// General Styles
/********************/
.text {
    color: var(--text-color)!important;
}

.contrast-bg {
    background-color: var(--bg-color-contrast1);
}

.contrast-bg2 {
    background-color: var(--bg-color-contrast2);
}

.contrast-bg3 {
    background-color: var(--bg-color-contrast3);
}

.contrast-dropdown {
    background-color: var(--bg-color-contrast4);
    border-color: $primary!important;
}

.mb-card {
    margin-bottom: 2rem !important;
}

.transitionDiv {
    // background: red;
    height: 100%;
    // width: 200px;
    margin: 0 auto;
  }
  
  @keyframes inAnimation {
    0% {
      opacity: 0;
      visibility: hidden;
      height: 0%;
    }
    100% {
      opacity: 1;
      visibility: visible;
      height: 100%;
    }
  }
  
  @keyframes outAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }

.text-gradient {
    @include gradient(to left, $list1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/*******************/
// Style Overides
/********************/
body {
    background-color: var(--bg-color);
    color: var(--text-color);
    transition: background-color 0.5s ease;
  }

table {
    background-color: var(--bg-color-contrast1)!important;
    color: var(--text-color)!important;
}

input, select, textarea{
    color: var(--text-color)!important;
}

textarea:focus, input:focus {
    color: var(--text-color)!important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--text-color-muted)!important;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--text-color-muted)!important;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--text-color-muted)!important;
  }

.breadcrumb-item::before{
    color: var(--text-color)!important;
}

.card {
    background-color: var(--bg-color-contrast1)!important;
    color: var(--text-color)!important;
}

.list-group-item,
.list-group {
    background-color: var(--bg-color-contrast1)!important;
    color: var(--text-color)!important;
}

.list-group-item:hover {
    opacity: 0.7;
}

.list-group-item:active {
    opacity: 0.9;
}

.clickable-item:hover {
    opacity: 0.7;
}

.clickable-item:active {
    opacity: 0.9;
}

// .list-group-item.list-group-item-sub {
//     background-color: var(--bg-color-contrast2)!important;
// }

.text-muted {
    color: var(--text-color-muted)!important;
}

.text-purple {
    color: $purple!important;
}

.table {
    background-color: var(--bg-color-contrast1)!important;
    color: var(--text-color)!important;
}

.form-select,
.form-control, .form-control:focus {
    background-color: var(--bg-color);
    color: var(--text-color);
    border: 0px solid;
}

.form-control:disabled {
    background-color: var(--bg-color-contrast2);
    color: var(--text-color-muted);
    border: 0px solid;
}

.form-check-input {
    background-color: var(--bg-color);
    color: var(--text-color);
    border: 0px solid;
}


.apexcharts-canvas > svg {
    background-color: transparent !important;
}

.pagination,
.page-link,
.page-item
 {
    background-color: var(--bg-color-contrast1);
    color: var(--text-color);
    .active {
        background-color: $primary!important;
        color: $white!important;
        :hover {
            background-color: $primary!important;
            color: $white!important;
            opacity: 0.8;
        }
        :focus {
            background-color: $primary!important;
            color: $white!important;
        }
    }
    .disabled {
        background-color: var(--bg-color-contrast2)!important;
    }
    .selected {
        background-color: var(--bg-color-contrast1);
        color: var(--text-color);
        opacity: 0.8;
    }
    :focus {
        background-color: var(--bg-color-contrast1);
        color: var(--text-color);
    }
    :hover {
        background-color: var(--bg-color-contrast1);
        color: var(--text-color);
        opacity: 0.8;
    }
}

.dropdown-item,
.dropdown-menu {
    background-color: var(--bg-color-contrast1)!important;
    color: var(--text-color)!important;
    .dropdown-item:hover {
        opacity: 0.8;
    }
    .dropdown-item:active {
        opacity: 0.5;
    }
}
.dropdown-header {
    color: var(--text-color-muted);
    opacity: 0.8;
}

.accordion,
.accordion-item {
    background-color: var(--bg-color-contrast1);
    color: var(--text-color);
}
.accordion-button:not(.collapsed){
    background-color: var(--bg-color-contrast2);
    color: var(--text-color);
    
}
.accordion-button {
    background-color: var(--bg-color-contrast1);
    color: var(--text-color);
    
}

.nav-tabs {
    border: 0px solid;
}

.nav-tabs .nav-link {
    margin-bottom: 0!important;
    height: 100%!important;
}

.nav-tabs .nav-item {
    border-bottom: 3px solid var(--bg-color-contrast2);
}

.nav-tabs .nav-item:not(.active) {
    border-bottom: 3px solid var(--bg-color-contrast2);
}
.nav-tabs .nav-item:hover:not(.active) {
    border-bottom: 3px solid var(--bg-color-contrast3);
}
.nav-tabs .nav-item:focus:not(.active) {
    border-bottom: 3px solid var(--bg-color-contrast3);
}
.nav-tabs .nav-item:focus .active {
    border-bottom: 3px solid var(--bg-color-contrast3);
}
.nav-tabs .nav-item .active {
    border-bottom: 3px solid $dgreen!important;
}

.nav-tabs .nav-link.active {
    background-color: $primary!important;
    color: $white!important;
    border: 0px solid;
}


.nav-tabs .nav-link:hover:not(.active) {
    background-color: var(--bg-color-contrast2);
    border: 0px solid;
}
.nav-tabs .nav-item .active:hover {
    opacity: 0.8;
}

.custom-nav:hover {
    opacity: 0.8;
}
.custom-nav:active {
    opacity: 0.5;
}

.custom-nav-selected {
    font-weight: bold;
    color: $primary;
    border-bottom: solid $primary;
    padding-bottom: 0.5rem;
}

.custom-nav-warning {
    font-weight: bold;
    color: $warning;
    border-bottom: solid $warning;
    padding-bottom: 0.5rem;
}

.custom-nav-info {
    font-weight: bold;
    color: $info;
    border-bottom: solid $info;
    padding-bottom: 0.5rem;
}

.custom-nav-secondary {
    font-weight: bold;
    color: $secondary;
    border-bottom: solid $secondary;
    padding-bottom: 0.5rem;
}

.custom-header {
    border-bottom: solid $primary;
    padding-bottom: 0.5rem;
}

.card-top-notrounded {
    border-radius: 0 0 0.3rem 0.3rem;
}

.modal-content {
    background-color: var(--bg-color-contrast1);
}

hr.thick-jumbotron {
    background-color: $primary;
    height: 5px;
    width: 35px;
    border: 0;
    opacity: 100;
    margin-top: -0.1rem;
}

hr.thick-jumbotron-warning {
    background-color: $warning;
    height: 5px;
    width: 35px;
    border: 0;
    opacity: 100;
    margin-top: -0.1rem;
}

hr.thick-jumbotron-purple {
    background-color: $purple;
    height: 5px;
    width: 35px;
    border: 0;
    opacity: 100;
    margin-top: -0.1rem;
}

hr.thick-primary {
    background-color: $primary;
    height: 3.25px;
    width: 35px;
    border: 0;
    opacity: 100;
    margin-top: -0.25rem;
}

hr.thick-secondary {
    background-color: $secondary;
    height: 3.25px;
    width: 35px;
    border: 0;
    opacity: 100;
    margin-top: -0.25rem;
}

.alert {
    border: 0;
}

.alert-primary {
    color: $white;
    background-color: $primary;
}
.alert-secondary {
    color: $white;
    background-color: $secondary;
}
.alert-info {
    color: $white;
    background-color: $info;
}
.alert-danger {
    color: $white;
    background-color: $danger;
}
.alert-success {
    color: $white;
    background-color: $success;
}
.alert-warning {
    color: $white;
    background-color: $warning;
}
.alert-standard {
    color: var(--text-color);
    background-color: var(--bg-color-contrast2);
}

i.primary {
    color: $primary;
}
i.info {
    color: $info;
}
i.danger {
    color: $danger;
}
i.success {
    color: $success;
}
i.warning {
    color: $warning;
}
i.caution {
    color: $caution;
}
i.secondary {
    color: $secondary;
}
i.grey {
    color: $grey;
}


.text-primary {
    color: $primary;
}
.text-secondary {
    color: $secondary;
}

//https://codepen.io/sosuke/pen/Pjoqqp - filter colour gen
input[type=date]::-webkit-calendar-picker-indicator {
	filter: invert(76%) sepia(34%) saturate(784%) hue-rotate(31deg) brightness(78%) contrast(90%);
    cursor: pointer;
}
input[type=time]::-webkit-calendar-picker-indicator {
	filter: invert(76%) sepia(34%) saturate(784%) hue-rotate(31deg) brightness(88%) contrast(90%);
    cursor: pointer;
}
input[type=search]::-webkit-search-cancel-button {
	filter: invert(76%) sepia(34%) saturate(784%) hue-rotate(31deg) brightness(78%) contrast(90%);
    cursor: pointer;
}

select option:checked {
    background: var(--bg-color-contrast2)
}

.audio-play-button {
    background-color: #f1f3f4;
    color: #a8aaaa;
}

.audio-play-button-icon {
    font-size: 1rem;
    color: #000
}

.bi-circle-fill {
    font-size: xx-small;
    vertical-align: middle;
    margin-right: 1rem;
}

.colored-square {
    display: flex;
    background: var(--bg-color-contrast1);
    width: 3rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
}

.bg-grey {
    --bs-bg-opacity: 1;
    background-color: $grey!important;
  }

  .bg-caution {
    --bs-bg-opacity: 1;
    background-color: $caution!important;
  }

.bg-assist {
    --bs-bg-opacity: 1;
    background-color: $assist!important;
  }

.input-alternate {
background-color: var(--bg-color-contrast1);
color: var(--text-color);
border: 0px solid;
}
.input-alternate:focus {
    background-color: var(--bg-color-contrast1);
    color: var(--text-color);
}

.card-footer {
    background: none;
}

.text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .text-oneline {
    white-space: nowrap;
  }
  
  .no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }

  @media print {
    .no-print {
      display: none !important;
    }
  }

  .progress-container {
    margin-bottom: 20px;
  }
  
  .step-indicator-container {
    display: flex;
    position: relative;
  }
  
  .step-indicator {
    flex: 1;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
  
  .step-indicator .step-number {
    background-color: var(--bg-color-contrast4);
    color: var(--text-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 5px;
  }
  
  .step-indicator .step-title {
    display: block;
    font-size: 12px;
  }
  
  .step-indicator.active .step-number {
    background-color: $secondary;
    color: white;
  }

  .step-indicator.completed .step-number {
    background-color: $primary;
    color: white;
  }
  
  .progress-line {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--text-color);
    z-index: 0;
  }
  
  .step-indicator.active ~ .step-indicator .progress-line {
    background-color: $secondary;
  }
  
  .wizard-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

.markdown img, .markdown p{
    max-width: 100% !important;
    height: auto !important;
}